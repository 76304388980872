html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #bfd9ff40;
  overflow-x: hidden !important;
}

#rcorners {
  border-radius: 10% / 50%;
  border: 0px !important;
}

.widthLogo {
  width: 20%;
}

.search {
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  margin-left: 0px !important;

}

.rowAfterSearch {
  transition: 0.5s;
  transform: translate(-50%, -95%)
}

.info {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  margin-left: 0px !important;
}

.infoAfterSearch {
  top: 30%;
  animation: fadeIn 2s
}


@media (max-width: 480px) {
  .infoAfterSearch {
    top: 60%;
  }

  .widthLogo {
    width: 75%;
  }

  .search {
    position: relative;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    margin-left: 0px !important;
  }
}

@media (max-width: 1024px) {
  .search {
    position: relative;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    margin-left: 0px !important;

  }

  .info {
    position: relative;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    margin-left: 0px !important;
  }
}


@media (max-width: 900px) {

  .info {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    margin-left: 0px !important;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}